export const ProjectGroupCreateLocalization = {
  'ProjectGroupCreate:Title': {
    eng: 'Create a group',
    ru: 'Создать группу',
  },
  'ProjectGroupCreate:Name:Label': {
    eng: 'Group name',
    ru: 'Название группы',
  },
  'ProjectGroupCreate:Button:Cancel': {
    eng: 'Cancel',
    ru: 'Отмена',
  },
  'ProjectGroupCreate:Button:Submit': {
    eng: 'Create',
    ru: 'Создать',
  },
  'BasePage:BE-error common.validation.constraints.Pattern $.fullName': {
    eng: 'Invalid characters',
    ru: 'Недопустимые символы',
  },
};
