import React, { Fragment } from 'react';
import { BasePage, Error } from '../BasePage';
import { AppContext } from '../../components/AppContext';
import { Button } from '@just-ai/just-ui';
import localize, { t } from 'localization';
import ProjectGroupsService from '../../service/ProjectGroupService';
import LoginService from '../../service/LoginService';
import { history } from '../../App';
import { projectGroupSelectLocalization } from './localization/projectGroupSelect.loc';
import './style.scss';
import { AccountInvitationDto, AllowedAccountListItem } from '../../api/cloud/client';
import { withRouter } from 'react-router-dom';
import { getDomainData, isDev } from '../../pipes/functions';
// @ts-ignore
import Cookies from 'universal-cookie';

const cookies = new Cookies();

localize.addTranslations(projectGroupSelectLocalization);

class ProjectGroupSelect extends BasePage<
  any,
  {
    projectGroupList: AllowedAccountListItem[];
    projectGroupInvitationsList: AccountInvitationDto[];
    fetching: boolean;
    errors: Error[];
    loaded: boolean;
  }
> {
  static contextType = AppContext;
  ProjectGroupsService = new ProjectGroupsService();
  LoginService = new LoginService();

  state = {
    fetching: false,
    errors: [],
    loaded: true,
    projectGroupList: [],
    projectGroupInvitationsList: [],
  };

  componentDidMount() {
    const load = async () => {
      this.setState({ fetching: true });
      const { setCurrentUser } = this.context;
      try {
        const { data } = await this.LoginService.checkIsUserAuthorized();
        setCurrentUser(data);
        const projectGroupsData = await Promise.all([
          this.ProjectGroupsService.getAllowedAccounts(data.userData.userId),
          this.ProjectGroupsService.getInvitationsByUser(data.userData.userId),
        ]);
        this.setState({
          projectGroupList: projectGroupsData[0],
          projectGroupInvitationsList: projectGroupsData[1],
        });
      } catch {
        history.push('/c/login');
      }
      this.setState({ fetching: false });
    };

    load();
  }

  renderHead = () => {
    const { projectGroupList, projectGroupInvitationsList } = this.state;

    return (
      <div className='base-page_formarea-head select-group-page'>
        <h1>{t('ProjectGroupSelect:Header:Title')}</h1>
        <p>
          {projectGroupList?.length > 0 || projectGroupInvitationsList?.length > 0
            ? t('ProjectGroupSelect:Header:Text:WithGroups')
            : t('ProjectGroupSelect:Header:Text:NoGroups')}
        </p>
      </div>
    );
  };

  acceptInvitation = async (accountId: number) => {
    const { currentUser } = this.context;
    await this.ProjectGroupsService.acceptInvitationByUserId(currentUser.userData.userId, accountId);
    this.selectProjectGroup(accountId);
  };

  selectProjectGroup = (id: number) => {
    const {
      location: { search },
    } = this.props;
    const { appConfig } = this.context;

    let { redirectUrl } = getDomainData(search, appConfig?.domains);

    cookies.set('SELECTED_ACCOUNT', String(id), { path: '/' });

    window.location.href = isDev() ? '/' : (redirectUrl as string);
  };

  renderButtons = () => {
    const {
      appConfig: {
        domains,
        security: { usersCanCreateAccount },
      },
    } = this.context;

    const jaicp = domains?.jaicp;

    if (!usersCanCreateAccount) return;

    return (
      jaicp && (
        <div className='base-page_formarea-buttons center select-group-page'>
          <div>
            <p> {t('ProjectGroupSelect:CreateProjectGroup:Text')}</p>
          </div>
          <Button color='primary' onClick={() => history.push('/c/create-project-group')}>
            {t('ProjectGroupSelect:CreateProjectGroup:Button')}
          </Button>
        </div>
      )
    );
  };

  renderGroupsList = () => {
    const { projectGroupList, projectGroupInvitationsList } = this.state;
    const ownGroups = projectGroupList?.filter((group: AllowedAccountListItem) => group.owner);
    const notOwnGroups = projectGroupList?.filter((group: AllowedAccountListItem) => !group.owner);
    return (
      <div className='project-group-list'>
        {ownGroups?.map((group: AllowedAccountListItem, i) => (
          <Fragment key={`group_${group.name}`}>
            <GroupItem group={group} changeProjectGroup={this.selectProjectGroup} />
            <div className='divider' />
          </Fragment>
        ))}
        {notOwnGroups?.map((group: AllowedAccountListItem) => (
          <Fragment key={`group_${group.name}`}>
            <GroupItem group={group} changeProjectGroup={this.selectProjectGroup} />
            <div className='divider' />
          </Fragment>
        ))}
        {projectGroupInvitationsList?.map((group: AccountInvitationDto) => (
          <Fragment key={`group_invite_${group.accountName}`}>
            <div
              className='group-item'
              data-test-id={`ProjectGroupSelector.Group.${group.accountName}`}
              onClick={() => this.acceptInvitation(group.accountId)}
            >
              <span>{group.accountName}</span>
              <span className='invited-badge'>{t('ProjectGroupSelect:InvitedToGroupBadge')}</span>
            </div>
            <div className='divider' />
          </Fragment>
        ))}
      </div>
    );
  };

  renderBody = () => {
    return (
      <>
        {this.renderGroupsList()}
        {this.renderButtons()}
      </>
    );
  };
}

const GroupItem = ({
  group,
  changeProjectGroup,
}: {
  // group: AccountDataDto;
  group: any;
  changeProjectGroup: (id: number) => void;
}) => (
  <div
    className='group-item'
    data-test-id={`ProjectGroupSelector.Group.${group.name}`}
    onClick={() => changeProjectGroup(group.id)}
  >
    <span>{group?.default ? t('ProjectGroupSelect:DefaultGroup') : group?.name}</span>
  </div>
);

export default withRouter(ProjectGroupSelect);
