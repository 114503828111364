import React, { createRef, SyntheticEvent } from 'react';
import { BasePage, BasePropTypes, BaseStateTypes, Error } from '../BasePage';
import LoginService from '../../service/LoginService';
import * as queryString from 'querystring';
import { ResetPasswordDto } from '../../api/cloud/client';
import { history } from '../../App';
import classNames from 'classnames';
import { Button, Icon, InputText } from '@just-ai/just-ui/dist';
import { NavLink } from 'react-router-dom';
import PasswordResetService from '../../service/PasswordResetService';
import UniversalLoginService from 'service/UniversalLoginService';
import { t } from 'localization';
import { getDomainData, isAxiosError, isDev } from '../../pipes/functions';
import localize from '../../localization';
import { resetpasswordLocalization } from './localization/resetpassword.loc';

localize.addTranslations(resetpasswordLocalization);

const FIELDS = ['newPassword', 'repeatPassword'];

interface State extends BaseStateTypes {
  showPassword: boolean;
  showRepeatPassword: boolean;
  autoLoginError: Error | null;
  success: boolean;
}

export default class ResetPassword extends BasePage<BasePropTypes, State> {
  state: State = {
    showPassword: false,
    showRepeatPassword: false,
    errors: [],
    fetching: false,
    domainsToLoginList: [],
    autoLoginError: null,
    success: false,
    loaded: true,
  };

  form = {
    newPassword: createRef<HTMLInputElement>(),
    repeatPassword: createRef<HTMLInputElement>(),
  };

  LoginService = new LoginService();
  PasswordResetService = new PasswordResetService();
  UniversalLoginService = new UniversalLoginService();

  componentDidMount() {
    const load = async () => {
      const {
        location: { search },
      } = this.props;

      this.supportAddOnMessageListener();

      const parsedLocationSearch = queryString.parse(search?.replace('?', ''));

      if (parsedLocationSearch?.token) {
        try {
          await this.LoginService.autoLogin(parsedLocationSearch.token as string);
          const { data } = await this.UniversalLoginService.universalLoginRequest();
          this.setState({
            domainsToLoginList: data,
          });
        } catch (error) {
          if (isAxiosError(error)) {
            const errorData = error.response?.data;
            this.setState({
              autoLoginError: errorData,
            });
          }
        }
      }
    };

    load();
  }

  validate = (passwordObject: ResetPasswordDto) => {
    const { repeatPassword } = this.form;
    const { errors } = this.state;
    const repeatPasswordValue = repeatPassword.current?.value?.trim() || '';
    const fullPasswordObject = { ...passwordObject, repeatPassword: repeatPassword.current?.value?.trim() || '' };

    const commonErrors: Error[] = errors.filter((error: Error) => !error?.args?.path);

    let fieldErrors: Error[] = [];
    FIELDS.forEach((field: string) => {
      if (!fullPasswordObject[field as keyof ResetPasswordDto]) {
        fieldErrors.push({
          args: {
            path: field,
          },
        } as Error);
      }
    });
    if (passwordObject.newPassword && repeatPasswordValue && passwordObject.newPassword !== repeatPasswordValue) {
      fieldErrors.push({
        args: {
          path: 'newPassword',
        },
        error: 'password.not.match',
      });
    }

    this.setState({ errors: [...commonErrors, ...fieldErrors] });
    return fieldErrors.length === 0;
  };

  submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const { newPassword } = this.form;

    const passwordObject: ResetPasswordDto = {
      newPassword: newPassword.current?.value?.trim() || '',
    };

    if (!this.validate(passwordObject)) return;

    this.setState({
      fetching: true,
    });
    try {
      await this.PasswordResetService.resetPassword(passwordObject);
      this.setState({
        success: true,
        fetching: false,
      });
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data.errors || [error.response?.data];
        this.setState({
          errors: errors,
          fetching: false,
        });
      }
    }
  };

  togglePasswordShow = (type: 'showPassword' | 'showRepeatPassword') => () =>
    this.setState({ [type]: !this.state[type] } as any);

  renderInputs = () => {
    const { showPassword, showRepeatPassword, autoLoginError, success } = this.state;

    return (
      !autoLoginError &&
      !success && (
        <>
          <div
            className={classNames('form-row password-row', {
              'with-error': Boolean(this.renderFieldError('newPassword')),
            })}
          >
            <label htmlFor='newPassword'>{t(`ResetPassword: field password label`)}</label>
            <InputText
              type={showPassword ? 'text' : 'password'}
              name='newPassword'
              id='newPassword'
              innerRef={this.form.newPassword}
            />
            <Icon
              name={showPassword ? 'faEyeSlash' : 'faEye'}
              size='lg'
              onClick={this.togglePasswordShow('showPassword')}
            />
            {this.renderFieldError('newPassword')}
          </div>
          <div
            className={classNames('form-row password-row', {
              'with-error': Boolean(this.renderFieldError('repeatPassword')),
            })}
          >
            <label htmlFor='repeatPassword'>{t(`ResetPassword: field repeat password label`)}</label>
            <InputText
              type={showRepeatPassword ? 'text' : 'password'}
              name='repeatPassword'
              id='repeatPassword'
              innerRef={this.form.repeatPassword}
            />
            <Icon
              name={showRepeatPassword ? 'faEyeSlash' : 'faEye'}
              size='lg'
              onClick={this.togglePasswordShow('showRepeatPassword')}
            />
            {this.renderFieldError('repeatPassword')}
          </div>
        </>
      )
    );
  };

  handleErrorOrSuccessStateButtonClick = (e: SyntheticEvent) => {
    const { autoLoginError, success } = this.state;
    const {
      location: { search },
    } = this.props;
    const { appConfig } = this.context;
    const parsedLocationSearch = queryString.parse(search.replace('?', ''));
    const { redirectUrl, theme } = getDomainData(search, appConfig?.domains);
    if (autoLoginError) {
      e.preventDefault();
      const newParams = { redirectUrl: parsedLocationSearch.redirectUrl, theme: theme };
      const newSearch = '?' + queryString.stringify(newParams);
      history.push(`/c/forgot-password${newSearch}`);
    }
    if (success) {
      e.preventDefault();
      window.location.href = isDev() ? '/' : (redirectUrl as string);
    }
  };

  renderButtons = () => {
    const { autoLoginError, success } = this.state;
    let text = 'ResetPassword: submit button text';
    if (autoLoginError) text = 'ResetPassword: submit button error text';
    if (success) text = 'ResetPassword: submit button success text';
    return (
      <div
        className={classNames('base-page_formarea-buttons reset-password-buttons', {
          'button-with-auto': autoLoginError || success,
        })}
      >
        <Button color='primary' type='submit' onClick={this.handleErrorOrSuccessStateButtonClick}>
          {t(text)}
        </Button>
        {this.renderUniversalLoginPixels()}
      </div>
    );
  };

  renderHead = () => {
    const { autoLoginError, success } = this.state;
    let text = 'ResetPassword: form header text';
    if (autoLoginError?.error) text = `ResetPassword: form header error text ${autoLoginError?.error}`;
    if (success) text = 'ResetPassword: form header success text';
    return (
      <div className='base-page_formarea-head'>
        <h1>{t(text)}</h1>
      </div>
    );
  };

  renderFooter = () => {
    const { appConfig } = this.context;
    const { autoLoginError } = this.state;
    const { location } = this.props;

    return (
      autoLoginError && (
        <div className='base-page_formarea-footer'>
          <p>
            <NavLink className='mr-4' to={`/c/login${location?.search}`}>
              {t('Register: login link')}
            </NavLink>
            {appConfig.registration.enabled && (
              <NavLink to={`/c/register${location?.search}`}>{t('Login: register link')}</NavLink>
            )}
          </p>
        </div>
      )
    );
  };

  renderCaptcha = () => {};
}
