import React from 'react';
import { Icon } from '../Icon';
import './Banner.scss';
import classNames from 'classnames';
import { Button } from '../Button';
const IconByBannerType = {
    success: 'faCheckSquare',
    info: 'faInfoCircle',
    danger: 'faExclamationCircle',
    warning: 'faExclamationTriangle',
};
export function Banner({ BannerText, onClose, type = 'info', action, withIcon = false, className, iconName, }) {
    return (React.createElement("div", { className: classNames('justui_banner', type, className, { 'with-close': onClose }) },
        withIcon ? (React.createElement(Icon, { className: 'justui_banner-icon-type', name: iconName || IconByBannerType[type], color: type })) : null,
        React.createElement("div", { className: 'justui_banner-text' },
            React.createElement(BannerText, null)),
        action ? (React.createElement("div", { className: 'justui_banner-buttons' }, Array.isArray(action) ? (action.map(actionItem => (React.createElement(Button, { key: `Banner-button-${actionItem.text}`, size: 'sm', color: type, onClick: actionItem.callback }, actionItem.text)))) : (React.createElement(Button, { size: 'sm', color: type, onClick: action.callback }, action.text)))) : null,
        onClose ? (React.createElement("div", { className: 'justui_banner-close', onClick: onClose },
            React.createElement(Icon, { name: 'faTimes', color: 'secondary', size: 'sm' }))) : null));
}
