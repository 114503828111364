import React, { Component, createContext, useContext } from 'react';
import { getGoogleTag, getLangByCode, getUserLanguage } from '../../pipes/functions';
import localize from '../../localization';
import ConfigService from '../../service/ConfigService';
import { DomainOptions, OptionsResponseDto, UserDataDto } from '../../api/cloud/client';
import { Spinner } from '@just-ai/just-ui/dist';
import TagManager from 'react-gtm-module';
import tovieAgent from 'images/tovie/t-agent.svg';

export class AppContextType {
  loading: boolean = true;
  language: string = getUserLanguage();
  theme: string = '';
  domainOptions: DomainOptions | null = null;
  appConfig: OptionsResponseDto | null = null;
  currentUser: UserDataDto | null = null;
}

export class AppContextProviderType extends AppContextType {
  setLoading: () => void = () => {};
  setCurrentUser: (data: UserDataDto | null) => void = data => {};
  setLanguage: (language: string) => void = language => {};
}

export const AppContext = createContext<AppContextProviderType>(new AppContextProviderType());

export class AppContextProvider extends Component<{}, AppContextType> {
  state = new AppContextType();

  ConfigService = new ConfigService();

  componentDidMount() {
    this.getAppConfig().then(() => {
      let theme = '';

      let product = 'cc';
      let domainOptions: DomainOptions | null = null;
      let appTitle: string | undefined;
      const { appConfig } = this.state;
      const domains = appConfig?.['domains'];
      const languageAvailable = appConfig?.['language'];

      const tagManagerArgs = {
        gtmId: getGoogleTag(Boolean(appConfig?.euroInstance)),
      };
      TagManager.initialize(tagManagerArgs);

      if (appConfig && !theme) {
        const host = window.location.host;

        domains &&
          (Object.values(domains) as DomainOptions[]).forEach((domain: DomainOptions) => {
            if (domain.domain === host) {
              theme = domain.defaultTheme;
              product = domain.product;
              appTitle = domain.appTitle;
              domainOptions = domain;
            }
          });
      }

      // Include the Crisp code here, without the <script></script> tags
      // @ts-ignore
      window.$crisp = [];
      try {
        // @ts-ignore
        window.CRISP_WEBSITE_ID = domains[product]?.crispToken;
      } catch (e) {
        console.error(e);
      }

      (function () {
        var d = document;
        var s = d.createElement('script') as HTMLScriptElement;
        s.src = 'https://client.crisp.chat/l.js';
        s.async = true;
        d.getElementsByTagName('head')[0].appendChild(s);
      })();

      this.setAppData(theme as string, appTitle);
      const language = getUserLanguage(languageAvailable);
      localize.setLocale(language);
      this.setState({
        language: language,
        theme: theme as string,
        domainOptions: domainOptions,
      });
    });
  }

  getAppConfig = async () => {
    this.setState({
      loading: true,
    });
    try {
      const data = await this.ConfigService.getOptions();
      this.setState({
        appConfig: data.data,
        loading: false,
      });
    } catch (e) {}
  };

  setAppData = (theme: string, appTitle?: string) => {
    const { appConfig } = this.state;
    let link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;
    if (!Boolean(link)) {
      link = document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }
    const euroInstance = appConfig?.euroInstance;
    const description = document.querySelector('meta[name="description"]') as HTMLMetaElement;
    switch (theme) {
      case 'aimyvoice': {
        document.title = euroInstance ? 'TovieVoice' : 'AimyVoice';
        link.href = euroInstance ? '/c/tovie/t-voice.svg' : '/c/aimyvoice.svg';
        description.content = euroInstance ? 'TovieVoice' : 'Aimyvoice';
        break;
      }
      case 'caila': {
        document.title = 'Caila';
        link.href = '/c/caila.svg';
        description.content = 'Caila';
        break;
      }
      case 'jaicp': {
        document.title = euroInstance ? 'Tovie AI Platform' : 'Just AI JAICP';
        link.href = euroInstance ? '/c/tovie/t-plarform.svg' : '/c/jaicp.svg';
        description.content = '';
        break;
      }
      case 'aimylogic': {
        document.title = euroInstance ? 'Tovie DialogStudio' : 'Just AI Aimylogic';
        link.href = euroInstance ? '/c/tovie/t-dialogstudio.svg' : '/c/aimylogic.svg';
        description.content = '';
        break;
      }
      case 'aimychat': {
        document.title = euroInstance ? 'Tovie AI Agent' : 'Just AI Aimychat';
        link.href = euroInstance ? tovieAgent : '/c/aimychat.svg';
        description.content = '';
        break;
      }
      case 'copilot': {
        document.title = euroInstance ? 'Tovie AI Copilot' : 'Just AI Copilot';
        link.href = '/c/copilot.svg';
        description.content = '';
        break;
      }
      case 'cloud': {
        document.title = euroInstance ? 'Tovie AI Cloud' : 'Just AI Conversational Cloud';
        link.href = euroInstance ? '/c/tovie/t-cloud.svg' : '/c/cloud.svg';
        description.content = '';
        break;
      }
      default: {
        document.title = appTitle ? appTitle : euroInstance ? 'Tovie AI Cloud' : 'Just AI Conversational Cloud';
        link.href = euroInstance ? '/c/tovie/t-general-logo.svg' : '/c/general-logo.svg';
        description.content = '';
        break;
      }
    }
  };

  setLoading = () => {
    this.setState({
      loading: this.state.loading,
    });
  };

  setCurrentUser = (data: UserDataDto | null) => {
    if (data?.userData.language) this.setLanguage(getLangByCode(data?.userData.language));
    this.setState({ currentUser: data });
  };

  setLanguage = (language: string) => {
    localStorage['USER_LANGUAGE'] = language;
    localize.setLocale(language);
    this.setState({
      language: language,
    });
  };

  render() {
    const { loading, language, theme, domainOptions, appConfig, currentUser } = this.state;
    const { children } = this.props;
    return (
      <AppContext.Provider
        value={{
          loading: loading,
          setLoading: this.setLoading,
          language: language,
          theme: theme,
          domainOptions: domainOptions,
          appConfig: appConfig,
          currentUser: currentUser,
          setCurrentUser: this.setCurrentUser,
          setLanguage: this.setLanguage,
        }}
      >
        {loading ? <Spinner size='4x' /> : children}
      </AppContext.Provider>
    );
  }
}

export const useAppContext = () => useContext(AppContext);
