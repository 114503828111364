import React, { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { Icon } from '../Icon';
import { getSelectedItem } from './SelectedItem';
import cn from 'classnames';
export const JustSelectContainer = ({ focused, multiple, onFocus, selected, inputPlaceholder, searchValue, onChange, selectedItemComponent, selectedItemComponentProps, backSpaceDeleteSelected, iconLeft, deleteSelectedItem, cleanupSelected, autoFocus, iconArrowShow = true, invalid, disabled, withoutSearch, size = 'md', dropdownIconName = 'faJustSelect', hideMultipleSelectedOptions, dataTestId, }) => {
    var _a;
    const SelectedItemComponent = getSelectedItem(selectedItemComponent, selectedItemComponentProps);
    const inputElement = useRef(null);
    const deleteSelection = useCallback(selectedItem => () => {
        deleteSelectedItem(selectedItem);
    }, [deleteSelectedItem]);
    const handleClickArrow = (evt) => {
        var _a, _b, _c;
        const previousNode = evt.currentTarget.previousSibling;
        if ((previousNode === null || previousNode === void 0 ? void 0 : previousNode.nodeName) === 'DIV') {
            const justSelect = previousNode.previousSibling;
            (_a = justSelect) === null || _a === void 0 ? void 0 : _a.focus();
        }
        if ((previousNode === null || previousNode === void 0 ? void 0 : previousNode.nodeName) === 'SPAN') {
            (_b = previousNode) === null || _b === void 0 ? void 0 : _b.focus();
        }
        if ((previousNode === null || previousNode === void 0 ? void 0 : previousNode.nodeName) === 'INPUT') {
            (_c = previousNode) === null || _c === void 0 ? void 0 : _c.focus();
        }
    };
    const styleIconCleanUp = iconArrowShow ? { right: '2rem' } : {};
    useEffect(() => {
        var _a;
        if (autoFocus) {
            (_a = inputElement.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, []);
    return (React.createElement("div", { className: classNames(`just-select-container ${invalid ? 'error' : ''} ${size}`, { focused: focused }, { multiple: multiple }, { disabled: disabled }) }, !multiple ? (React.createElement(React.Fragment, null,
        iconLeft && React.createElement(Icon, { wrapperClassName: 'leftIcon', name: iconLeft, size: size }),
        withoutSearch ? (React.createElement("span", { className: cn('single', {
                withLeftIcon: iconLeft,
            }), tabIndex: 0, onKeyDown: e => {
                e.preventDefault();
            }, onFocus: onFocus, "data-test-id": `${dataTestId ? dataTestId + '_' : ''}just-select__single`, dangerouslySetInnerHTML: { __html: (_a = selected === null || selected === void 0 ? void 0 : selected[0]) === null || _a === void 0 ? void 0 : _a.label } })) : (React.createElement("input", { ref: inputElement, type: 'text', className: cn('single', {
                withLeftIcon: iconLeft,
            }), "data-test-id": `${dataTestId ? dataTestId + '_' : ''}just-select__single_search`, onFocus: onFocus, placeholder: selected.length > 0 ? selected[0].label : inputPlaceholder, value: selected.length > 0 && !focused && !searchValue ? selected[0].label : searchValue, onChange: onChange, onSubmit: e => e.stopPropagation(), autoFocus: autoFocus, "aria-invalid": invalid, disabled: disabled })),
        React.createElement("div", { className: 'just-select-container-open', onClick: handleClickArrow },
            React.createElement(Icon, { name: dropdownIconName, size: size })))) : (React.createElement(React.Fragment, null,
        !hideMultipleSelectedOptions &&
            (selected === null || selected === void 0 ? void 0 : selected.map(selectedItem => (React.createElement(SelectedItemComponent, { key: `selectedItem${selectedItem.value}`, label: selectedItem.label, onDelete: deleteSelection(selectedItem) })))),
        React.createElement("input", { ref: inputElement, type: 'text', className: 'multiple', "data-test-id": `${dataTestId ? dataTestId + '_' : ''}just-select__multiple_search`, onFocus: onFocus, autoFocus: autoFocus, onChange: onChange, onKeyDown: backSpaceDeleteSelected, value: searchValue || '', onSubmit: e => e.stopPropagation(), "aria-invalid": invalid, disabled: disabled, placeholder: hideMultipleSelectedOptions ? inputPlaceholder : '' }),
        !hideMultipleSelectedOptions && selected.length > 0 && (React.createElement("div", { className: 'just-select-selected-item-cleanup', onClick: cleanupSelected, style: styleIconCleanUp },
            React.createElement(Icon, { name: 'faTimesCircle', size: size }))),
        iconArrowShow && (React.createElement("div", { className: 'just-select-container-open', "data-test-id": 'just-select-container-open-icon', onClick: handleClickArrow },
            React.createElement(Icon, { name: dropdownIconName, size: size })))))));
};
