import { UniversalLoginEndpointApi } from '../api/cloud/client';
import { axios } from '../pipes/functions';

export default class UniversalLoginService {
  static readonly BASE_PATH = '';
  private UniversalLoginApi: UniversalLoginEndpointApi;

  constructor() {
    this.UniversalLoginApi = new UniversalLoginEndpointApi({}, UniversalLoginService.BASE_PATH, axios);
  }

  universalLoginRequest = async () => {
    try {
      return await this.UniversalLoginApi.universalLoginRequest();
    } catch (error) {
      throw error;
    }
  };
}
