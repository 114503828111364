export const footerLocalization = {
  'Footer: ToS link text': {
    eng: 'Terms of Service',
    ru: 'Соглашение об использовании',
    cn: 'Terms of Service',
  },
  'Footer: policy link text': {
    eng: 'Privacy Policy',
    ru: 'Политика обработки персональных данных',
    cn: 'Privacy Policy',
  },
  'Footer: privacy policy link en': {
    eng: 'https://just-ai.com/eng/docs/pp.pdf',
    ru: 'https://just-ai.com/eng/docs/pp.pdf',
    cn: 'https://just-ai.com/eng/docs/pp.pdf',
  },
  'Footer: privacy policy link ru': {
    eng: 'https://just-ai.com/public_html/wp-content/themes/justai_ru/docs/privacy-policy/pp.pdf',
    ru: 'https://just-ai.com/public_html/wp-content/themes/justai_ru/docs/privacy-policy/pp.pdf',
    cn: 'https://just-ai.com/public_html/wp-content/themes/justai_ru/docs/privacy-policy/pp.pdf',
  },
  'Footer: terms of use link en': {
    eng: 'https://just-ai.com/eng/docs/tos.pdf',
    ru: 'https://just-ai.com/eng/docs/tos.pdf',
    cn: 'https://just-ai.com/eng/docs/tos.pdf',
  },
  'Footer: terms of use link ru': {
    eng: 'https://just-ai.com/wp-content/themes/justai_ru/docs/terms-of-service/tos.pdf',
    ru: 'https://just-ai.com/wp-content/themes/justai_ru/docs/terms-of-service/tos.pdf',
    cn: 'https://just-ai.com/wp-content/themes/justai_ru/docs/terms-of-service/tos.pdf',
  },
};
