import { OptionsEndpointApi } from '../api/cloud/client';
import { axios } from '../pipes/functions';

export default class ConfigService {
  static readonly BASE_PATH = '';
  private ConfigApi: OptionsEndpointApi;

  constructor() {
    this.ConfigApi = new OptionsEndpointApi({}, ConfigService.BASE_PATH, axios);
  }

  getOptions = async () => {
    try {
      return await this.ConfigApi.getOptions();
    } catch (error) {
      throw error;
    }
  };
}
