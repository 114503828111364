import React, { Component } from 'react';
import { Spinner } from '@just-ai/just-ui';
import LoginService from 'service/LoginService';
import queryString from 'query-string';
import { getDomainData, isDev } from '../../pipes/functions';
import { AppContext } from '../../components/AppContext';
import UniversalLoginService from 'service/UniversalLoginService';
import { DomainToLoginItem } from '../BasePage';

class ChangeAccount extends Component {
  static contextType = AppContext;

  state = {
    domainsToLoginList: [],
  };

  LoginService = new LoginService();
  UniversalLoginService = new UniversalLoginService();

  componentDidMount() {
    this.changeAccount();
  }

  changeAccount = async () => {
    const {
      // @ts-ignore
      location: { search },
    } = this.props;
    const { appConfig } = this.context;

    const parsedLocationSearch = queryString.parse(search.replace('?', ''));

    const { redirectUrl } = getDomainData(search, appConfig?.domains);
    const accountId =
      parsedLocationSearch?.accountId === 'null' ? undefined : parseInt(parsedLocationSearch?.accountId as string, 10);
    try {
      await this.LoginService.changeAccount(accountId);
      const { data } = await this.UniversalLoginService.universalLoginRequest();
      this.setState({
        domainsToLoginList: data,
      });

      setTimeout(() => {
        this.setState({
          fetching: false,
        });
        window.location.href = isDev() ? '/' : (redirectUrl as string);
      }, 3000);
    } catch (e) {}
  };

  render() {
    const { domainsToLoginList } = this.state;
    return (
      <>
        <Spinner size='4x' />
        {domainsToLoginList && domainsToLoginList.length > 0 && (
          <div className='universal-login-pixels'>
            {(domainsToLoginList as DomainToLoginItem[]).map(domain => (
              <img
                key={`universal_login_${domain.host}`}
                src={`${window.location.protocol}//${domain.host}/api/accountsadmin/public/universal/login/login.gif?token=${domain.token}`}
                alt=''
              />
            ))}
          </div>
        )}
      </>
    );
  }
}

export default ChangeAccount;
