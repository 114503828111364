import React from 'react';
import { DropdownButton, DropdownItem, DropdownMenu, DropdownToggle } from '../Dropdown';
import { getProductName, logoByProduct } from './utils';
import './style.scss';
import { menuButton } from './icons';
const CloudMenu = ({ cloudDomains, countryIsoCode, universalLoginMenuEnabled, translate, isEuroInstance = false, }) => {
    const filteredMenu = Object.keys(cloudDomains).filter(domain => cloudDomains[domain].showInNavigation);
    if (!universalLoginMenuEnabled || (universalLoginMenuEnabled && filteredMenu.length < 2)) {
        return null;
    }
    return (React.createElement("li", { className: 'nav-item cloud-menu-wrap' },
        React.createElement(DropdownButton, { direction: 'down' },
            React.createElement(DropdownToggle, null,
                React.createElement("div", { className: 'menu-button' }, menuButton)),
            React.createElement(DropdownMenu, { className: 'product-dropdown' }, filteredMenu.map(domain => (React.createElement(DropdownItem, { key: `header_menu_${cloudDomains[domain].domain}` },
                React.createElement("a", { href: `${window.location.protocol}//${cloudDomains[domain].domain}`, style: (window.location.host === cloudDomains[domain].domain && window.location.pathname !== '/c/profile') ||
                        (countryIsoCode !== 'RU' && cloudDomains[domain].product === 'aimyvoice')
                        ? { pointerEvents: 'none' }
                        : undefined },
                    logoByProduct[getProductName(cloudDomains[domain].product, isEuroInstance)],
                    React.createElement("span", null, translate(`CloudMenu: ${getProductName(cloudDomains[domain].product, isEuroInstance)}`))))))))));
};
export default CloudMenu;
