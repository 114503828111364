export const headerLocalization = {
  'CloudMenu: aimylogic': {
    eng: 'Aimylogic',
    ru: 'Aimylogic',
    cn: 'Aimylogic',
  },
  'CloudMenu: aimyvoice': {
    eng: 'Aimyvoice',
    ru: 'Aimyvoice',
    cn: 'Aimyvoice',
  },
  'CloudMenu: caila': {
    eng: 'Caila NLP Platform',
    ru: 'Caila NLP Platform',
    cn: 'Caila NLP Platform',
  },
  'CloudMenu: cc': {
    eng: 'Conversational Cloud',
    ru: 'Conversational Cloud',
    cn: 'Conversational Cloud',
  },
  'CloudMenu: jaicp': {
    eng: 'JAICP',
    ru: 'JAICP',
    cn: 'JAICP',
  },
  'CloudMenu: aimychat': {
    eng: 'Aimychat',
    ru: 'Aimychat',
  },
  'Header: profile': {
    eng: 'Profile',
    ru: 'Профиль',
    cn: 'Profile',
  },
  'Header: logout': {
    eng: 'Sign out',
    ru: 'Выйти',
    cn: 'Sign out',
  },
};
