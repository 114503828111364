import React, { createRef, SyntheticEvent } from 'react';
import { BasePage, BaseStateTypes, Error } from '../BasePage';
import classNames from 'classnames';
import { Icon, InputText, Button } from '@just-ai/just-ui/dist';
import { t } from 'localization';
import { NavLink } from 'react-router-dom';
import queryString from 'query-string';
import { LoginDto } from '../../api/cloud/client';
import { history } from '../../App';
import LoginService from '../../service/LoginService';
import UniversalLoginService from '../../service/UniversalLoginService';
import { getDomainData, isAxiosError, isDev, langToUpperCase, sendAnalyticEvents } from '../../pipes/functions';
import { github, google } from '../BasePage/icons';
import localize from '../../localization';
import { loginLocalization } from './localization/login.loc';
import ProjectGroupsService from 'service/ProjectGroupService';
import Cookies from 'universal-cookie';

localize.addTranslations(loginLocalization);

const LOGIN_FIELDS = ['email', 'password'];
const PRODUCTS_TO_REDIRECT_INTERNALS = ['caila'];
const PRODUCTS_TO_REDIRECT_USERS = ['aimychat', 'jaicp', 'caila'];

const cookies = new Cookies();
interface LoginState extends BaseStateTypes {
  showPassword: boolean;
  errors: Error[] | [];
  fetching: boolean;
}

export default class Login extends BasePage<any, LoginState> {
  name = 'Login';

  state = {
    showPassword: false,
    errors: [],
    fetching: false,
    domainsToLoginList: [],
    loaded: false,
  };

  ProjectGroupsService = new ProjectGroupsService();

  form = {
    email: createRef<HTMLInputElement>(),
    password: createRef<HTMLInputElement>(),
  };

  LoginService = new LoginService();
  UniversalLoginService = new UniversalLoginService();

  componentDidMount() {
    this.supportAddOnMessageListener();
    const {
      location: { search },
    } = this.props;
    const { appConfig, language } = this.context;
    const { redirectUrl, product } = getDomainData(search, appConfig.domains);
    const load = async () => {
      const {
        // @ts-ignore
        location: { search },
      } = this.props;
      const queryParams = queryString.parse(search.replace('?', ''));

      if (queryParams?.token && !appConfig.authorization.onpremOpenIDAuthorizationEnabled) {
        try {
          await this.LoginService.autoLogin(queryParams?.token as string);
        } catch (e) {
        } finally {
          try {
            const { data } = await this.UniversalLoginService.universalLoginRequest();
            this.setState({
              domainsToLoginList: data,
            });

            setTimeout(() => {
              this.setState({
                fetching: false,
              });
              window.location.href = isDev() ? '/' : (queryParams?.redirectUrl as string);
            }, 3000);
          } catch (e) {
            history.push({ search: 'error=accountsadmin.autologin.invalid.token' });
            this.setState({
              fetching: false,
              loaded: true,
              errors: [{ error: 'accountsadmin.autologin.invalid.token' }],
            });
          }
        }
      } else {
        try {
          await this.LoginService.checkIsUserAuthorized();
          window.location.href = '/';
        } catch (e) {
          const {
            // @ts-ignore
            location: { search },
          } = this.props;
          if (appConfig.authorization.onpremOpenIDAuthorizationEnabled) {
            localStorage.CLOUD_REDIRECT_URL = redirectUrl;
            return (window.location.href = `/api/accountsadmin/public/oauth2/authorization/openid?product=${product}&language=${langToUpperCase(
              language
            )}`);
          }

          this.setState({ loaded: true });

          if (isAxiosError(e) && e.response?.data.error === 'accountsadmin.email.verification.not.verified') {
            history.push('/c/verify-email');
          }
          let parsedLocationSearch = queryString.parse(search.replace('?', ''));
          if (
            parsedLocationSearch?.error === 'accountsadmin.autologin.invalid.token' ||
            parsedLocationSearch?.['change-email-result'] === 'failed'
          ) {
            this.setState(
              {
                errors: [{ error: 'accountsadmin.autologin.invalid.token' }],
              },
              () => {
                parsedLocationSearch.error = null;
                const stringifiedSearch = queryString.stringify(parsedLocationSearch);
                window.history.pushState(
                  '',
                  '',
                  `${window.location.pathname}${stringifiedSearch ? '?' + stringifiedSearch : ''}`
                );
              }
            );
          }
        }
      }
    };

    load();

    this.form.email.current?.focus();
  }

  validate = (loginObject: LoginDto) => {
    const { errors } = this.state;

    const commonErrors: Error[] = errors.filter((error: Error) => !error?.args?.path);

    let fieldErrors: { args: { path: string } }[] = [];
    LOGIN_FIELDS.forEach((field: string) => {
      if (!loginObject[field as keyof LoginDto]) {
        fieldErrors.push({
          args: {
            path: field,
          },
        });
      }
    });

    this.setState({ errors: [...commonErrors, ...fieldErrors] });
    return fieldErrors.length === 0;
  };

  submit = async (e: SyntheticEvent) => {
    e.preventDefault();
    const { appConfig } = this.context;
    const { email, password } = this.form;
    const {
      location: { search },
    } = this.props;

    const loginObject: LoginDto = {
      email: email.current?.value?.trim() || '',
      password: password.current?.value?.trim() || '',
    };

    if (!this.validate(loginObject)) return;

    this.setState({
      fetching: true,
    });

    try {
      const { data: loginData } = await this.LoginService.login(loginObject);
      const { data } = await this.UniversalLoginService.universalLoginRequest();
      this.setState({
        domainsToLoginList: data,
      });

      sendAnalyticEvents(
        {
          event: 'GAEvent',
          eventAction: 'login success',
          eventCategory: 'login',
          options: { userId: loginData.userData.userId },
        },
        {
          event: 'reachGoal',
          eventType: 'login',
        },
        { eventType: 'login' },
        { event: 'trackCustom', eventType: 'Login' }
      );

      let { redirectUrl, productToRedirect } = getDomainData(search, appConfig?.domains);

      // if is user internal (e.g. sadmin or acc.manager) force redirecting to jaicp sadmin panel
      if (!isDev() && loginData.userData.internal) {
        if (PRODUCTS_TO_REDIRECT_INTERNALS.includes(productToRedirect as string)) {
          return (window.location.href = redirectUrl as string);
        }
        return (window.location.href = `${window.location.protocol}//${appConfig?.domains.jaicp.domain}`);
      }

      if (!isDev() && !loginData.userData.accountId) {
        const projectGroupList = await this.ProjectGroupsService.getAllowedAccounts(loginData.userData.userId);

        if (projectGroupList.length === 1 && projectGroupList[0].id !== undefined) {
          cookies.set('SELECTED_ACCOUNT', String(projectGroupList[0].id), { path: '/' });
        } else if (PRODUCTS_TO_REDIRECT_USERS.includes(productToRedirect as string)) {
          return history.push(`/c/select-project-group?redirectUrl=${redirectUrl}`);
        } else {
          return history.push('/c/only-platform');
        }
      }

      setTimeout(() => {
        window.location.href = isDev() ? '/' : (redirectUrl as string);
      }, 3000);
    } catch (error) {
      if (isAxiosError(error)) {
        const errors = error.response?.data.errors || [error.response?.data];
        const emailNotVerifiedError = errors.find(
          (error: Error) => error.error === 'accountsadmin.email.verification.not.verified'
        );

        if (emailNotVerifiedError) {
          history.push(`/c/verify-email`);
        }

        this.setState({
          errors: errors,
          fetching: false,
        });
      }
    }
  };

  togglePasswordShow = () => this.setState({ showPassword: !this.state.showPassword });

  toLoginWithPhone = (e: SyntheticEvent) => {
    const { location } = this.props;
    e.preventDefault();
    history.push(`/c/login-with-phone${location?.search}`);
  };

  renderInputs = () => {
    const { appConfig } = this.context;
    const { showPassword } = this.state;

    return (
      <>
        <div className={classNames('form-row', { 'with-error': Boolean(this.renderFieldError('email')) })}>
          <label htmlFor='email'>{t(`Register: field email label`)}</label>
          <InputText name='email' id='email' innerRef={this.form.email} placeholder='user@example.com' />
          {this.renderFieldError('email')}
        </div>
        <div
          className={classNames('form-row password-row', {
            'with-error': Boolean(this.renderFieldError('password')),
          })}
        >
          <label htmlFor='password'>{t(`Register: field password label`)}</label>
          <InputText
            type={showPassword ? 'text' : 'password'}
            name='password'
            id='password'
            innerRef={this.form.password}
          />
          <Icon name={showPassword ? 'faEyeSlash' : 'faEye'} size='lg' onClick={this.togglePasswordShow} />
          {appConfig.security.usersCanResetPassword && (
            <div className='forgot-password-link'>
              <NavLink to='/c/forgot-password'>{t('Login: forgot password')}</NavLink>
            </div>
          )}
          {this.renderFieldError('password')}
        </div>
      </>
    );
  };

  renderButtons = () => {
    const { domainOptions, appConfig } = this.context;

    const notPartOfCC = !domainOptions?.partOfConversationalCloud;
    return (
      <div className='base-page_formarea-buttons login-buttons'>
        <Button color='primary'>{t('Register: submit button text')}</Button>
        {this.renderUniversalLoginPixels()}
        {(appConfig.authorization.smsCodeAuthorizationEnabled ||
          appConfig.authorization.oauth2AuthorizationEnabled) && (
          <div className='to-other-login'>
            <p>{t('PhoneVerificationAndLogin: login with')}</p>
            <div className={classNames('to-other-login-buttons', { 'not-part-of-cc': notPartOfCC })}>
              {appConfig.authorization.smsCodeAuthorizationEnabled && !notPartOfCC && (
                <Button color='secondary' outline onClick={this.toLoginWithPhone}>
                  <Icon name='faPhoneAlt' /> {t('Login: login with phone')}
                </Button>
              )}
              {appConfig.authorization.oauth2AuthorizationEnabled && (
                <>
                  <Button color='secondary' outline onClick={this.loginWithGoogleOrGithub(true)}>
                    {google} {t('Login: login with google')}
                  </Button>
                  {!notPartOfCC && (
                    <Button color='secondary' outline onClick={this.loginWithGoogleOrGithub(false)}>
                      {github} {t('Login: login with github')}
                    </Button>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  renderHead = () => {
    return (
      <div className='base-page_formarea-head'>
        <h1>{t('Login: form header text')}</h1>
      </div>
    );
  };

  renderFooter = () => {
    const { appConfig } = this.context;
    const { location } = this.props;

    return (
      <div className='base-page_formarea-footer login'>
        {appConfig.registration.enabled && (
          <p>
            {t('Login: no account')}
            <NavLink to={`/c/register${location?.search}`}>{t('Login: register link')}</NavLink>
          </p>
        )}
      </div>
    );
  };

  renderCaptcha = () => {};
}
