export const projectGroupSelectLocalization = {
  'ProjectGroupSelect:Header:Title': {
    eng: 'Project groups',
    ru: 'Группы проектов',
  },
  'ProjectGroupSelect:Header:Text:WithGroups': {
    eng: 'You have access to multiple project groups, you will be able to switch between them. Choose the group:',
    ru: 'Вам доступно несколько групп проектов, вы cможете переключаться между ними. Выберите группу:',
  },
  'ProjectGroupSelect:Header:Text:NoGroups': {
    eng: 'You do not have any project groups yet. You will soon receive an invitation to the group.',
    ru: 'У вас еще нет ни одной группы проектов. Скоро вы получите приглашение в группу.',
  },
  'ProjectGroupSelect:CreateProjectGroup:Text': {
    eng: 'or create a new one',
    ru: 'или создайте новую',
  },
  'ProjectGroupSelect:CreateProjectGroup:Button': {
    eng: 'Create project group',
    ru: 'Создать группу проектов',
  },
  'ProjectGroupSelect:InvitedToGroupBadge': {
    eng: 'You were invited',
    ru: 'Вас пригласили',
  },
  'ProjectGroupSelect:DefaultGroup': {
    eng: 'My projects',
    ru: 'Мои проекты',
  },
};
