import React, { Component } from 'react';
import { createBrowserHistory } from 'history';
import Routes from './routes';
import { AppContextProvider } from './components/AppContext';
import Theme from './components/Theme';

export const history = createBrowserHistory();

class App extends Component<any, any> {
  componentDidMount() {
    this.setCustomCssHeightVar();

    window.addEventListener('resize', this.setCustomCssHeightVar);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setCustomCssHeightVar);
  }

  setCustomCssHeightVar = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  render() {
    return (
      <div className='App'>
        <AppContextProvider>
          <Theme>
            <Routes />
          </Theme>
        </AppContextProvider>
      </div>
    );
  }
}

export default App;
