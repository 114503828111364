import React from 'react';
import { BasePage, BasePropTypes, BaseStateTypes, Error } from '../BasePage';
import { t } from 'localization';
import { Button } from '@just-ai/just-ui';
import * as queryString from 'query-string';
import ResendTimer from './ResendTimer';
import { Banner } from '@just-ai/just-ui/dist';
import { history } from '../../App';
import EmailVerificationService from 'service/EmailVerificationService';
import UniversalLoginService from '../../service/UniversalLoginService';
import { SendVerificationEmailDto } from '../../api/cloud/client';
import { NavLink } from 'react-router-dom';
import LoginService from '../../service/LoginService';
import { getDomainData, isAxiosError, isDev } from '../../pipes/functions';
import localize from '../../localization';
import { verifyemailLocalization } from './localization/verifyemail.loc';

localize.addTranslations(verifyemailLocalization);

interface VerifyEmailState extends BaseStateTypes {
  email: string;
  fetching: boolean;
  token: string;
  success: boolean;
  needWaitMinute: boolean;
  remainingTime: number;
  errors: Error[] | [];
  error: boolean;
}

class VerifyEmail extends BasePage<BasePropTypes, VerifyEmailState> {
  EmailVerificationService = new EmailVerificationService();
  UniversalLoginService = new UniversalLoginService();
  LoginService = new LoginService();

  state = {
    email: '',
    fetching: false,
    token: '',
    success: false,
    needWaitMinute: false,
    remainingTime: 0,
    errors: [],
    error: false,
    domainsToLoginList: [],
    loaded: true,
  };

  async componentDidMount() {
    const {
      // @ts-ignore
      location: { search },
    } = this.props;
    const queryParams = queryString.parse(search);

    this.supportAddOnMessageListener();

    if (queryParams && !queryParams.token) {
      try {
        const {
          location: { search },
        } = this.props;
        const { appConfig } = this.context;
        this.setState({
          fetching: true,
        });
        const {
          data: { userData },
        } = await this.LoginService.checkIsUserAuthorized();

        if (userData.emailVerified) {
          const { redirectUrl } = getDomainData(search, appConfig?.domains);
          window.location.href = isDev() ? '/' : (redirectUrl as string);
        }
      } catch (e) {}
    }

    try {
      if (queryParams?.token) {
        this.setState({
          token: (queryParams?.token as string) || '',
        });
        await this.verifyEmail();
      }
      const { data } = await this.EmailVerificationService.getVerificationEmailInfo();

      this.setState({
        email: data?.email as string,
        needWaitMinute: data.remainingTimeMs > 0,
        remainingTime: Math.ceil(data.remainingTimeMs / 1000),
      });
    } catch (error) {
      if (isAxiosError(error) && error?.response?.data?.error === 'common.access.denied') {
        history.push('/c/login');
      }
    }
  }

  finishTimer = () => {
    this.setState({
      remainingTime: 0,
    });
  };

  verifyEmail = async () => {
    const { domainOptions, appConfig } = this.context;
    const {
      location: { search },
    } = this.props;
    const queryParams = queryString.parse(search.replace('?', ''));
    const newQueryParams = { redirectUrl: queryParams?.redirectUrl };
    const newQueryParamsString = '?' + queryString.stringify(newQueryParams);

    const { redirectUrl } = getDomainData(search, appConfig?.domains);

    try {
      await this.LoginService.autoLogin(queryParams?.token as string);
      const { data } = await this.UniversalLoginService.universalLoginRequest();
      this.setState({
        domainsToLoginList: data,
      });

      if (domainOptions?.showPhoneVerification) {
        history.push(`/c/phone-verification${newQueryParamsString}`);
      } else {
        window.location.href = isDev() ? '/' : (redirectUrl as string);
      }
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error.response?.data;
        const errors = errorData.errors?.length > 0 ? errorData.errors : [errorData.error];
        this.setState({
          error: true,
          errors: errors,
        });
        throw error;
      }
    }
  };

  sendVerificationMail = async () => {
    const { appConfig } = this.context;
    const {
      location: { search },
    } = this.props;

    const { product, redirectUrl } = getDomainData(search, appConfig?.domains);

    const verificationObject: SendVerificationEmailDto = {
      redirectUrl: redirectUrl as string,
    };

    if (product) verificationObject.product = product;

    try {
      await this.EmailVerificationService.sendVerificationMail(verificationObject);
    } catch (error) {}
    const time = await this.EmailVerificationService.getVerificationEmailInfo();

    const remainingTimeMs = typeof time?.data.remainingTimeMs === 'number' ? time?.data.remainingTimeMs : 0;
    const remainingTimeSec = Math.round(remainingTimeMs / 1000);

    this.setState({
      needWaitMinute: true,
      remainingTime: remainingTimeSec,
    });
  };

  renderArea = () => {
    const { email, token, success, remainingTime, errors } = this.state;
    return (
      <div className='base-page_formarea base-page_email-verification'>
        <h1>{t('VerifyEmail: title')}</h1>
        {!token && <h3 dangerouslySetInnerHTML={{ __html: t('VerifyEmail: text', email) }} />}
        {errors?.length > 0 && (
          <Banner
            type='danger'
            withIcon
            BannerText={() => (
              <div>
                {errors.map(error => (
                  <div key={`commonError_${error}`}>{t(`VerifyEmail:BE-error ${error}`)}</div>
                ))}
              </div>
            )}
          />
        )}

        {success && <h3 dangerouslySetInnerHTML={{ __html: t('VerifyEmail: success') }} />}
        {!success && !token && Boolean(remainingTime) && remainingTime > 0 && (
          <ResendTimer timer={remainingTime} finishTimer={this.finishTimer} />
        )}
        <div className='base-page_email-verification-buttons'>
          {!token && (
            <Button
              color='primary'
              onClick={this.sendVerificationMail}
              disabled={Boolean(remainingTime) && remainingTime > 0}
            >
              {t('VerifyEmail: button')}
            </Button>
          )}
        </div>
        {this.renderUniversalLoginPixels()}
        {this.renderFooter()}
      </div>
    );
  };

  renderFooter = () => {
    const { location } = this.props;

    return (
      <div className='base-page_formarea-footer'>
        <p>
          {t('VerifyEmail: have account')}
          <NavLink to={`/c/logout${location?.search}`}>{t('Register: login link')}</NavLink>
        </p>
      </div>
    );
  };

  renderCaptcha = () => {};
}

export default VerifyEmail;
