var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useEffect, useRef, useState } from 'react';
import { AnswerReplyType } from '../RichTextEditor/types';
import { ru, enUS } from 'date-fns/locale';
import { format } from 'date-fns';
import { isNil } from 'lodash';
import { useTranslation } from '../contexts/transtationContext';
export function useForceUpdate() {
    const [, setValue] = useState(true);
    return useCallback(() => setValue(value => !value), []);
}
export const normalize = (s) => (s ? s.toUpperCase().trim() : '');
export const toggleElementInArray = (array, element) => {
    const newArray = array ? [...array] : [];
    if (newArray.includes(element)) {
        newArray.splice(newArray.indexOf(element), 1);
    }
    else {
        newArray.push(element);
    }
    return newArray;
};
export const useClientRect = (node) => {
    const [rect, setRect] = useState(null);
    /*console.log(node);
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }*/
    useCallback(node => {
        if (node !== null) {
            setRect(node.getBoundingClientRect());
        }
    }, []);
    return [rect];
};
const normalizeRangeValue = (initialValue, minValue, maxValue) => typeof initialValue === 'number'
    ? initialValue
    : typeof minValue === 'number'
        ? minValue
        : typeof maxValue === 'number'
            ? maxValue
            : 0;
export const useRange = (initialValue, minValue, maxValue) => {
    const [value, setValue] = useState(() => normalizeRangeValue(initialValue, minValue, maxValue));
    return [
        value,
        (newValue) => {
            if (newValue === value)
                return;
            if (typeof minValue === 'number' && newValue < minValue) {
                setValue(minValue);
            }
            else if (typeof maxValue === 'number' && newValue > maxValue) {
                setValue(maxValue);
            }
            else {
                setValue(newValue);
            }
        },
    ];
};
export const useToggle = (initialValue = false) => {
    const [value, setValue] = useState(initialValue);
    const SetTrueCallback = useCallback(() => {
        setValue(true);
    }, []);
    const SetFalseCallback = useCallback(() => {
        setValue(false);
    }, []);
    const ToggleCallback = useCallback(() => {
        setValue(value => !value);
    }, []);
    return [value, SetTrueCallback, SetFalseCallback, ToggleCallback];
};
export const getPosX = (event) => {
    if (event instanceof MouseEvent) {
        return event.pageX || event.clientX;
    }
    else {
        return event.touches[0].pageX || event.touches[0].clientX;
    }
};
export const getPosY = (event) => {
    if (event instanceof MouseEvent) {
        return event.pageY || event.clientY;
    }
    else {
        return event.touches[0].pageY || event.touches[0].clientY;
    }
};
export const useOnClickOutside = (ref, handler) => {
    useEffect(() => {
        const listener = (event) => {
            if (!ref.current || ref.current.contains(event.target)) {
                return;
            }
            handler(event);
        };
        document.addEventListener('mousedown', listener);
        return () => {
            document.removeEventListener('mousedown', listener);
        };
    }, [ref, handler]);
};
export function usePrevious(value) {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = useRef();
    // Store current value in ref
    useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes
    // Return previous value (happens before update in useEffect above)
    return ref.current;
}
export function prettyJson(value) {
    return JSON.stringify(value, null, 2);
}
export const cancelDefaults = (event) => {
    event.preventDefault();
    event.stopPropagation();
};
export { useDebounceFn } from './useDebounceFn';
export { useThrottleFn } from './useThrottleFn';
export * from './diffInArrays';
export { default as usePromiseProcessing } from './usePromiseProcessing';
export { default as usePromisifyComponent } from './usePromisifyComponent';
export { useBehaviorSubject } from './useBehaviorSubject';
export { default as waitForAsPromise } from './waitForAsPromise';
export { waitPromiseTime } from './testing/waitTime';
export const generateUniqId = () => {
    return '_' + Math.random().toString(36).substr(2, 9);
};
export const repliesToAnswerParts = (replies) => {
    const getMainFieldByType = (reply) => {
        switch (reply.type) {
            case AnswerReplyType.text:
                return reply.text;
            case AnswerReplyType.file:
                return reply.fileUrl;
            case AnswerReplyType.audio:
                return reply.audioUrl;
            case AnswerReplyType.image:
                return reply.imageUrl;
            default:
                return reply.text;
        }
    };
    return replies.map(reply => ({
        type: reply.type,
        value: getMainFieldByType(reply) || '',
        audioName: reply.audioName,
        fileName: reply.fileName,
        markup: reply.markup,
        mimeType: reply.mimeType,
        uploadDate: reply.uploadDate,
        id: reply.key || generateUniqId(),
    }));
};
export const validateUrl = (value) => {
    try {
        new URL(value);
        return true;
    }
    catch (error) {
        return false;
    }
};
export function useFormatDate(date, formatString) {
    const { getLocale } = useTranslation();
    if (isNil(date))
        return '';
    const locale = () => {
        if (getLocale() === 'ru')
            return ru;
        return enUS;
    };
    return format(date, formatString, { locale: locale() });
}
export function defaultPreventer(event) {
    event.preventDefault();
}
export function findMatchPositionsInText(text, value) {
    const matches = [];
    let cursor = 0;
    while (cursor <= text.length - 1) {
        const findStartIndex = text.toLowerCase().indexOf(value, cursor);
        if (findStartIndex === -1)
            break;
        let to = findStartIndex + value.length;
        matches.push({
            from: findStartIndex,
            to,
        });
        cursor = to;
    }
    return matches;
}
export const downloadWithCheck = ({ url, axios, errorHandler, errorMessage, defaultError, localize, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c;
    const { t, tWithPlural } = localize;
    try {
        yield axios.head(url);
        window.location.href = url;
    }
    catch (error) {
        if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 429) {
            return errorHandler(tWithPlural(errorMessage, (_c = (_b = error.response) === null || _b === void 0 ? void 0 : _b.headers) === null || _c === void 0 ? void 0 : _c['x-rate-limit-retry-after-seconds']));
        }
        return errorHandler(t(defaultError));
    }
});
