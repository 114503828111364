import React, { useCallback, useEffect } from 'react';
import LoginService from '../../service/LoginService';
import { history } from '../../App';
import queryString from 'query-string';
import { Spinner } from '@just-ai/just-ui/dist';

const Logout = ({ location: { search } }: { location: { search: string } }) => {
  const logout = useCallback(async () => {
    try {
      const LogoutService = new LoginService();
      const parsedLocationSearch = queryString.parse(search.replace('?', ''));
      await LogoutService.logout();
      localStorage.removeItem('CURRENT_USER');
      if (parsedLocationSearch?.redirectUrl) {
        window.location.href = parsedLocationSearch?.redirectUrl as string;
      } else {
        history.push(`/c/login`);
      }
    } catch (e) {}
  }, [search]);

  useEffect(() => {
    logout();
  }, [logout]);

  return <Spinner size='4x' />;
};

export default React.memo(Logout);
