import React from 'react';
import './index.scss';
import { logo } from './Logo';
import { DropdownButton, DropdownMenu, DropdownToggle, Icon } from '@just-ai/just-ui/dist';
import { useAppContext } from '../AppContext';
import { OptionsResponseDto } from '../../api/cloud/client';
import { t } from 'localization';
import { DropdownItem } from '@just-ai/just-ui/dist';
import { getLogoByProduct } from '../../pipes/functions';
import { history } from '../../App';
import { Link } from 'react-router-dom';
import localize from '../../localization';
import { headerLocalization } from './localization/header.loc';
import CloudMenu from '@just-ai/just-ui/dist/CloudMenu';

localize.addTranslations(headerLocalization);

const Header = () => {
  // @ts-ignore
  const { appConfig, currentUser }: OptionsResponseDto | null = useAppContext();

  const { domains, euroInstance } = appConfig;

  return (
    <div className='header'>
      <div className='logo'>
        <Link to='/'>{logo}</Link>
      </div>
      <div className='header-menu-wrap'>
        <DropdownButton direction='down'>
          <DropdownToggle>
            <div
              className='user-menu-button'
              style={
                currentUser.userData.avatarUrl
                  ? { backgroundImage: `url(${currentUser.userData.avatarUrl})` }
                  : undefined
              }
            >
              {!currentUser.userData.avatarUrl && <Icon name='falUser' color='primary' />}
            </div>
          </DropdownToggle>
          <DropdownMenu>
            <DropdownItem onClick={() => history.push('/c/profile')}>{t('Header: profile')}</DropdownItem>
            <DropdownItem onClick={() => history.push('/c/logout')}>
              <span style={{ color: 'var(--gray-600)' }}>{t('Header: logout')}</span>
            </DropdownItem>
          </DropdownMenu>
        </DropdownButton>
        <CloudMenu
          cloudDomains={domains}
          countryIsoCode={currentUser?.userData?.countryIsoCode}
          translate={t}
          universalLoginMenuEnabled
          isEuroInstance={euroInstance}
        />
      </div>
    </div>
  );
};

export default Header;
