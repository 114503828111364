import React, { lazy, Suspense } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { history } from './App';
import Logout from './views/Logout';
import Full from './components/Full';
import { Spinner } from '@just-ai/just-ui/dist';
import PhoneVerificationAndLogin from './views/PhoneVerificationAndLogin';
import OauthPage from './views/OauthPage';
import Register from './views/Register';
import Login from './views/Login';
import VerifyEmail from './views/VerifyEmail';
import ForgotPassword from './views/ForgotPassword';
import ResetPassword from './views/ResetPassword';
import CountrySelect from './views/CountrySelect';
import OnlyJaicp from './views/OnlyJaicp';
import ChangeAccount from './views/ChangeAccount';
import Page404 from './views/Page404';
import ProjectGroupSelect from './views/ProjectGroupSelect';
import ProjectGroupCreate from './views/ProjectGroupCreate';

const UserProfile = lazy(() => import('./views/UserProfile'));
const MainPage = lazy(() => import('./views/MainPage'));

const Routes = () => {
  return (
    <Router history={history}>
      <Suspense fallback={<Spinner size='4x' />}>
        <Switch>
          <Route path='/c/register' exact component={Register} />
          <Route path='/c/login' exact component={Login} />
          <Route path='/c/login/oauth2' exact component={OauthPage} />
          <Route path='/c/login-with-phone' exact component={PhoneVerificationAndLogin} />
          <Route path='/c/verify-email' exact component={VerifyEmail} />
          <Route path='/c/forgot-password' exact component={ForgotPassword} />
          <Route path='/c/reset-password' exact component={ResetPassword} />
          <Route path='/c/phone-verification' exact component={PhoneVerificationAndLogin} />
          <Route path='/c/select-country' exact component={CountrySelect} />
          <Route path='/c/only-platform' exact component={OnlyJaicp} />
          <Route path='/c/change-account' exact component={ChangeAccount} />
          <Route path='/c/select-project-group' exact component={ProjectGroupSelect} />
          <Route path='/c/create-project-group' exact component={ProjectGroupCreate} />
          <Route path='/c/logout' exact component={Logout} />
          <Full>
            <Switch>
              <Route path='/c' exact component={MainPage} />
              <Route path='/' exact component={MainPage} />
              <Route path='/c/profile' exact component={UserProfile} />
              <Route component={Page404} />
            </Switch>
          </Full>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
