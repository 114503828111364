import React, { Component } from 'react';
import LoginService from '../../service/LoginService';
import { history } from '../../App';
import { Spinner } from '@just-ai/just-ui/dist';
import Header from '../Header';
import chatSupportController from '../../helpers/chatSupportController';
import Footer from '../Footer';
import { AppContext } from '../AppContext';
import * as queryString from 'query-string';
import { getDomainData, isDev } from '../../pipes/functions';
import UniversalLoginService from 'service/UniversalLoginService';
import { DomainToLoginItem } from '../../views/BasePage';

class Full extends Component<
  { location?: { pathname: string; search: string } },
  { fetching: boolean; domainsToLoginList?: DomainToLoginItem[] | [] }
> {
  static contextType = AppContext;
  timer: any;

  state = {
    fetching: false,
    domainsToLoginList: [],
  };

  supportTimer: any;

  LoginService = new LoginService();
  UniversalLoginService = new UniversalLoginService();

  componentDidMount() {
    const { location } = this.props;
    const { domainOptions, appConfig } = this.context;

    if (location?.pathname === '/c') {
      history.push('/');
    }

    if (!isDev() && !domainOptions?.ccMainPageAllowed) {
      const { redirectUrl } = getDomainData(location?.search as string, appConfig?.domains);
      window.location.href = isDev() ? '/' : (redirectUrl as string);
    }

    this.supportAddOnMessageListener();

    const load = async () => {
      const { setCurrentUser } = this.context;

      const {
        // @ts-ignore
        location: { search },
      } = this.props;
      const queryParams = queryString.parse(search.replace('?', ''));

      this.setState({ fetching: true });

      let tokenError = '';

      if (queryParams?.token) {
        try {
          await this.LoginService.autoLogin(queryParams?.token as string);
          const { data } = await this.UniversalLoginService.universalLoginRequest();
          this.setState({
            domainsToLoginList: data,
          });

          if (
            !isDev() &&
            queryParams?.redirectUrl &&
            queryParams?.redirectUrl !== window.location.origin &&
            queryParams?.redirectUrl !== window.location.href
          ) {
            setTimeout(() => {
              window.location.href = queryParams?.redirectUrl as string;
            }, 3000);
          }
        } catch (error) {
          if (
            window.location.pathname === '/c/profile' &&
            !isDev() &&
            queryParams?.redirectUrl &&
            !queryParams?.redirectUrl.includes(window.location.href)
          ) {
            window.location.href = (queryParams?.redirectUrl as string).replace(
              'change-email-result=success',
              'change-email-result=failed'
            );
          }
          tokenError = 'error=accountsadmin.autologin.invalid.token';
          history.push({ search: 'error=accountsadmin.autologin.invalid.token' });
        }
        if (
          window.location.pathname === '/c/profile' &&
          !isDev() &&
          queryParams?.redirectUrl &&
          !queryParams?.redirectUrl.includes(window.location.href) &&
          queryParams?.redirectUrl !== window.location.origin
        ) {
          return;
        }
      }

      try {
        const { data } = await this.LoginService.checkIsUserAuthorized();
        await setCurrentUser(data);

        if (!isDev() && !data.userData.accountId) {
          return history.push('/c/only-platform');
        }

        // if is user internal (e.g. sadmin or acc.manager) force redirecting to jaicp sadmin panel
        if (!isDev() && data.userData.internal) {
          window.location.href = `${window.location.protocol}//${appConfig?.domains.jaicp.domain}`;
        }
        if (
          appConfig.registration.userNeedToSpecifyCountryIsoCode &&
          !data.userData.countryIsoCode &&
          !data.userData.internal &&
          data.userData.accountOwner
        ) {
          history.push(`/c/select-country${search}`);
        }
        this.setState({
          fetching: false,
        });
      } catch (e) {
        history.push(`/c/login${location?.search}${tokenError ? (location?.search ? '&' : '?') + tokenError : ''}`);
      }
    };

    load();
    this.timer = setTimeout(this.checkIsUserAuthorizedbyInterval, 10000);
  }

  checkIsUserAuthorizedbyInterval = async () => {
    const { location } = this.props;
    try {
      await this.LoginService.checkIsUserAuthorized();
      this.timer = setTimeout(this.checkIsUserAuthorizedbyInterval, 10000);
    } catch (e) {
      history.push(`/c/login${location?.search}`);
    }
  };

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  supportAddOnMessageListener = () => {
    try {
      chatSupportController.init();
      chatSupportController.show();
    } catch (e) {
      this.supportTimer = setTimeout(this.supportAddOnMessageListener, 500);
    }
  };

  renderUniversalLoginPixels = () => {
    const { domainsToLoginList } = this.state;
    return (
      domainsToLoginList &&
      domainsToLoginList.length > 0 && (
        <div className='universal-login-pixels'>
          {(domainsToLoginList as DomainToLoginItem[]).map(domain => (
            <img
              key={`universal_login_${domain.host}`}
              src={`${window.location.protocol}//${domain.host}/api/accountsadmin/public/universal/login/login.gif?token=${domain.token}`}
              alt=''
            />
          ))}
        </div>
      )
    );
  };

  render() {
    const { fetching } = this.state;
    const { currentUser, appConfig } = this.context;
    const { children } = this.props;

    if (fetching || !currentUser || !appConfig?.ccProfileEnabled)
      return (
        <>
          <Spinner size='4x' />
          {this.renderUniversalLoginPixels()}
        </>
      );

    return (
      <div className='wrapper'>
        <Header />
        <div className='main' style={{ minHeight: 'calc(100vh - 163px)' }}>
          {children}
        </div>
        <Footer />
        {this.renderUniversalLoginPixels()}
      </div>
    );
  }
}

export default Full;
